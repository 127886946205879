'use client';

import React from 'react';
import {
  Box,
  HStack,
  Image,
  Show,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/react';
import type { CustomLayer } from '@nivo/line';
import dynamic from 'next/dynamic';

import {
  FinalPoints,
  Legend,
  MobileLineDataDisplay,
  Points,
  type SpecialLayerProps,
} from '@components/Graphs/_components';
import { formatProjection, nivoTheme } from '@components/Graphs/_helpers';
import { sanityImage } from '@lib/integrations/sanity';
import { abbreviateUSD, capitalize, getProjections } from '@raise/common';
import type { HomepageDocument } from '@raise/sanity';

const ResponsiveLine = dynamic(
  () => import('@nivo/line').then((m) => m.ResponsiveLine),
  { ssr: false },
);

type Props = HomepageDocument['investment']['graph'] & {
  logo: HomepageDocument['investment']['logo'];
  colorScheme: HomepageDocument['investment']['colorScheme'];
};

const Assumptions: React.FC<
  Pick<Props, 'logo' | 'versus' | 'competition_logos'> & { assumption: string }
> = ({ assumption, logo, versus, competition_logos }) => (
  <Stack
    direction={['column', 'row']}
    spacing={[2, 4, 8]}
    justifyContent="center"
    alignItems="center"
    mb={8}
    wrap="wrap"
  >
    <Text
      w={['full', null, null, 'auto']}
      textAlign="center"
      mb={[2, 4, null, 0]}
      color="gray.700"
      fontWeight="semibold"
      fontSize={['xl', '2xl']}
    >
      {assumption}
    </Text>
    <Image src={sanityImage(logo).url()} h={10} alt="Raise Financial" />
    <Text color="gray.400" fontWeight="semibold" fontSize="2xl">
      {versus}
    </Text>
    <HStack spacing={[4, 6, 8]} alignItems="center">
      {competition_logos.map((logo) => (
        <Image
          key={logo.name}
          src={sanityImage(logo.image).url()}
          h={[8, null, 10]}
          filter="contrast(0)"
          opacity={0.5}
          alt={logo.name}
        />
      ))}
    </HStack>
  </Stack>
);

const InvestmentGraph: React.FC<Props> = ({
  amounts,
  per_month,
  logo,
  versus,
  competition_logos,
  after,
  year,
  years,
  net,
  raise,
  market,
  colorScheme,
}) => {
  // Project the data
  const raiseProjection = getProjections(
    { initial: amounts.amount * 200 },
    amounts.returns,
    amounts.years,
    true,
  );
  const marketProjection = getProjections(
    { monthly: amounts.amount },
    amounts.returns,
    amounts.years,
    false,
  );

  // Get the Chakra theme
  const theme = useTheme();

  // Declare the ref for the portal
  const portalRef = React.useRef<HTMLDivElement>(null);

  // Get the colors
  const raiseColor = theme?.colors?.[colorScheme.value]['500'];
  const marketColor = theme?.colors?.gray['400'];

  // Turn the projections into a data array
  const data = [
    {
      id: 'raise',
      name: raise,
      color: raiseColor,
      data: formatProjection(raiseProjection, raise),
    },
    {
      id: 'market',
      name: market,
      color: marketColor,
      data: formatProjection(marketProjection, market),
    },
  ];

  // Get the last data points
  const lastRaiseDatum = data[0].data[data[0].data.length - 1];
  const lastMarketDatum = data[1].data[data[1].data.length - 1];

  // Get the max Y value
  const yMax = Math.ceil((lastRaiseDatum.y * 1.1) / 100000) * 100000;

  // Define the ticks for the bottom axis
  const bottomAxisTicks = Array.from(Array(amounts.years))
    .map((_, i) => (i % 5 === 0 ? i + 4 : null))
    .filter((v) => v);

  // Define the ticks for the left axis
  const leftAxisTicks = Array.from(Array(6))
    .map((_, i) => yMax - (yMax / 6) * i)
    .filter((v) => v)
    .reverse();

  // Define the margins
  const margins = { top: 10, right: 25, bottom: 25, left: 60 };

  return (
    <Box
      my={[8, 12, 16]}
      bg="white"
      p={[6, 8]}
      borderRadius="xl"
      boxShadow={`0px 30px 60px -30px ${
        theme?.colors[colorScheme.value]['300']
      }`}
    >
      <Assumptions
        assumption={`$${amounts.amount} ${per_month}`}
        logo={logo}
        versus={versus}
        competition_logos={competition_logos}
      />
      <Show below="md">
        <MobileLineDataDisplay
          after={after}
          years={years}
          net={net}
          colorScheme={colorScheme}
          data={[lastRaiseDatum, lastMarketDatum]}
        />
      </Show>
      <Show above="md">
        <Box h={[36, 72, null, 96]} position="relative">
          <ResponsiveLine
            data={data}
            margin={margins}
            colors={(item) => item.color}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 0,
              max: yMax,
              nice: true,
            }}
            curve="basis"
            axisBottom={{
              tickValues: bottomAxisTicks,
              format: (v) => `${capitalize(year)} ${v + 1}`,
            }}
            axisLeft={{
              tickValues: leftAxisTicks,
              format: (v) => abbreviateUSD(v),
            }}
            enableGridX={true}
            enableGridY={true}
            lineWidth={5}
            enablePoints={false}
            isInteractive={true}
            useMesh
            enableSlices="x"
            sliceTooltip={() => null}
            layers={[
              'grid',
              'markers',
              'axes',
              'areas',
              'crosshair',
              FinalPoints,
              Points as CustomLayer,
              'lines',
              'points',
              'slices',
              'mesh',
              'legends',
              (d) => (
                <Legend
                  after={after}
                  year={year}
                  years={years}
                  net={net}
                  margins={margins}
                  innerRef={portalRef}
                  {...(d as SpecialLayerProps)}
                />
              ),
            ]}
            theme={nivoTheme(theme)}
          />
          <Box ref={portalRef} position="absolute" top={0} left={0} />
        </Box>
      </Show>
    </Box>
  );
};

export default InvestmentGraph;
