import type { WithCSSVar } from '@chakra-ui/react';
import type { LineProps, Point } from '@nivo/line';

import type { CompoundingProjections } from '@raise/common';

export const getAll = (ps: Point[], serieId: string) =>
  ps.filter((p) => p.serieId === serieId);

export const getLast = (ps: Point[], serieId: string) => {
  const res = getAll(ps, serieId);

  return res[res.length - 1];
};

export const formatProjection = (
  proj: CompoundingProjections[],
  name: string,
) =>
  proj.map((p, i) => ({
    x: i,
    y: +p.net.toFixed(2),
    name,
  }));

export const nivoTheme = (theme: WithCSSVar<any>): LineProps['theme'] => ({
  axis: {
    ticks: {
      text: {
        fill: theme?.colors?.gray['400'],
        fontSize: 14,
      },
      line: {
        strokeWidth: 0,
      },
    },
  },
  grid: {
    line: {
      stroke: theme?.colors?.gray['200'],
    },
  },
  crosshair: {
    line: {
      stroke: theme?.colors?.gray['500'],
    },
  },
});
