'use client';

import { useEffect, useState } from 'react';
import { Box, Heading } from '@chakra-ui/react';

import Underline from '@components/Underline';
import type { HomepageDocument } from '@raise/sanity';

const Tagline: React.FC<HomepageDocument['hero']['tagline']> = ({
  text,
  nouns,
}) => {
  // The amount of time between slides
  const TIMEOUT = 5000;

  // The slide that we're on
  const [index, setIndex] = useState(0);

  // Increment the slide every X seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index + 1 >= nouns.length ? 0 : index + 1));
    }, TIMEOUT);

    return () => clearInterval(interval);
  }, [nouns.length]);

  // The width of the current word
  const [currentWordWidth, setCurrentWordWidth] = useState('auto');

  // When the word changes, we need to update the width of the box
  useEffect(() => {
    const wordElems = document.querySelectorAll('.hero-tagline-words');
    setCurrentWordWidth(`${wordElems[index].clientWidth}px`);
  }, [index]);

  return (
    <Heading
      as="h1"
      size="2xl"
      textAlign="center"
      w="full"
      mt={[6, 8, 12, null, 0]}
    >
      {text}{' '}
      <Box
        display="inline-block"
        position="relative"
        minW="1em"
        minH="1em"
        transitionDuration="0.5s"
        transitionProperty="transform width"
        style={{ width: currentWordWidth }}
      >
        {nouns.map((noun, i) => (
          <Box
            key={noun}
            className="hero-tagline-words"
            position="absolute"
            top={[0.5, null, 2]}
            left={0}
            opacity={index === i ? 1 : 0}
            transitionDuration="0.5s"
            transitionProperty="opacity"
          >
            <Underline>{noun}</Underline>
          </Box>
        ))}
      </Box>
    </Heading>
  );
};

export default Tagline;
