'use client';

import type { TextProps, ThemeTypings } from '@chakra-ui/react';
import { Text, useBreakpoint } from '@chakra-ui/react';

export type UnderlineProps = TextProps & {
  color?: ThemeTypings['colors'];
  fontWeight?: ThemeTypings['fontWeights'];
  height?: ThemeTypings['sizes'] | string;
};

const Underline: React.FC<UnderlineProps> = ({
  color = 'brand.500',
  fontWeight = 'semibold',
  height = '2px',
  ...props
}) => {
  const breakpoint = useBreakpoint();

  if (breakpoint === 'base' || breakpoint === 'sm') {
    return (
      <Text
        as="span"
        fontWeight={fontWeight}
        color={color}
        textDecoration="underline"
        {...props}
      />
    );
  }

  return (
    <Text
      as="span"
      display="inline-block"
      position="relative"
      fontWeight={fontWeight}
      color={color}
      _after={{
        content: "''",
        position: 'absolute',
        width: 'full',
        height,
        bottom: 0,
        left: 0,
        backgroundColor: color,
      }}
      {...props}
    />
  );
};

export default Underline;
